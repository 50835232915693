import axios from "axios";

const API_URL = "https://team.jcntechnology.in/inios/public/api/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("isLoggedIn", 1);
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("isLoggedIn");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getLoggedinUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.user) {
    return user.user;
  }
};

const authObject = {
  register,
  login,
  logout,
  getCurrentUser,
  getLoggedinUser
};
export default authObject;
