import React, { Component } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Navigate } from "react-router-dom";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import Copyright from "./footer";
import BasicBreadcrumbs from "./breadcrumbs";
import Button from "@mui/material/Button";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default class Upsiinfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      message: "",
      data: {},
      latestSrNo: 0,
      open:false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  handleDt = (key, val) => {
    this.setState({
      data: {
        ...this.state.data,
        [key]: val,
      },
    });
  };

  handleSubmit = (event) => {
    this.setState({
      isLoading: true,
    });
    event.preventDefault();
    // eslint-disable-next-line no-console
    UserService.addUPSI(this.state.data).then(
      (response) => {
        this.setState({
          data: response.data.data,
          message: response.data.message,
          isLoading: false,
          open:true,
        });
        this.fetchlatestSrNo();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          this.setState({
            message: resMessage,
            isLoading: false,
          });
      }
    );
    event.target.reset();
  };

  fetchlatestSrNo = () => {
    this.setState({ isLoading: true });
    UserService.getLatestSrNo().then(
      (response) => {
        this.handleDt("srno", response.data.LatestNo);
        this.setState({
          latestSrNo: response.data.LatestNo,
          isLoading: false,
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        this.setState({
          data: _content,
          isLoading: false,
        });
      }
    );
    this.setState({ isLoading: true });
  };

  componentDidMount() {
    this.fetchlatestSrNo();
    const UPSISharedBy = AuthService.getLoggedinUser();
    if (UPSISharedBy && UPSISharedBy.id) {
      this.handleDt("shared_by", UPSISharedBy.id);
    }
  }

  render() {
    if (!localStorage.getItem("isLoggedIn")) {
      // if true the user will be redirected to /dashboard
      return <Navigate to={{ pathname: "/" }} />;
    }
    const { isLoading, latestSrNo, open } = this.state;
    const UPSISharedBy = AuthService.getLoggedinUser();
    return (
      <Container maxWidth="false" sx={{ mt: 0, mb: 4 }}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <BasicBreadcrumbs name="Add UPSI Detail" />
        <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }} onClose={this.handleClose}>
        <Alert onClose={this.handleClose} variant="filled" severity="success" sx={{ width: '100%' }}>
          Your record has been succuessfully saved!
        </Alert>
      </Snackbar>
        <Box component="form" onSubmit={this.handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="srno"
                name="srno"
                label="Sr.No"
                fullWidth
                //autoComplete="given-name"
                variant="standard"
                value={latestSrNo}
                contentEditable={false}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="shared_by"
                name="shared_by"
                label="UPSI shared by"
                fullWidth
                //autoComplete="family-name"
                variant="standard"
                value={UPSISharedBy.name}
                contentEditable={false}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="shared_with"
                name="shared_with"
                label="UPSI shared with"
                fullWidth
                //autoComplete="shipping address-line1"
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="org_name"
                name="org_name"
                label="Organisation name"
                fullWidth
                //autoComplete="shipping address-line2"
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="pan_no"
                name="pan_no"
                label="PAN"
                fullWidth
                //autoComplete="shipping address-level2"
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="other_identifier_type_no"
                name="other_identifier_type_no"
                label="Other Identifier Type & Number"
                fullWidth
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                required
                id="shared_date_a"
                name="shared_date_a"
                label="Date of sharing UPSI (A)"
                fullWidth
                //autoComplete="shipping country"
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="shared_details"
                name="shared_details"
                label="Details of UPSI shared"
                fullWidth
                //autoComplete="shipping country"
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="shared_mode"
                name="shared_mode"
                label="Mode of UPSI sharing"
                fullWidth
                //autoComplete="shipping postal-code"
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                id="shared_date_b"
                name="shared_date_b"
                label="Date when information ceases to be UPSI (B)"
                fullWidth
                //autoComplete="shipping country"
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                id="remark"
                name="remark"
                label="Remarks, if any, for cessation"
                fullWidth
                //autoComplete="shipping country"
                variant="standard"
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
              Save
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
  }
}
