import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://team.jcntechnology.in/inios/public/api/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUPSIData = (userid) => {
  return axios.post(
    API_URL + "upsilist",
    {userid},
    { headers: authHeader() }
  );
};

const addUPSI = (data) => {
  console.log(data)
  return axios.post(
    API_URL + "addupsi",
    data,
    { headers: authHeader() }
  );
};

const getLatestSrNo = () => {
  return axios.get(
    API_URL + "getlatestno",
    { headers: authHeader() }
  );
};

const getBillView = (from_date, to_date, site_id) => {
  return axios.post(
    API_URL + "billview",
    { from_date, to_date, site_id },
    { headers: authHeader() }
  );
};

const getSites = () => {
  return axios.post(API_URL + "sites", {}, { headers: authHeader() });
};

const getSitesDetails = () => {
  return axios.post(API_URL + "sitedetails", {}, { headers: authHeader() });
};

const forgotPassword = (email) => {
  return axios.post(API_URL + "forgotPass", { headers: authHeader() });
};

const ChangePassword = (old_password, new_password, confirm_password) => {
  return axios.post(
    API_URL + "change_password",
    { old_password, new_password, confirm_password },
    { headers: authHeader() }
  );
};

const userObject = {
  getPublicContent,
  getUPSIData,
  addUPSI,
  getSites,
  forgotPassword,
  ChangePassword,
  getBillView,
  getLatestSrNo,
  getSitesDetails
};
export default userObject;
