import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
/*import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';*/
import AddIcon from '@mui/icons-material/Add';
/*import LegendToggleIcon from '@mui/icons-material/LegendToggle';*/
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";

const logout = () => {
  AuthService.logout();
  window.location.href ='/';
};

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/home">
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
    <ListItem button component={Link} to="/upsiinfo">
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText primary="Add UPSI" />
    </ListItem>
{/*     <ListItem button component={Link} to="/sitelist">
      <ListItemIcon>
        <CloseFullscreenIcon />
      </ListItemIcon>
      <ListItemText primary="Status" />
    </ListItem> */}
    <ListItem button component={Link} to="/dataview">
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <ListItemText primary="UPSI List" />
    </ListItem>
    {/* <ListItem button component={Link} to="/totflow">
      <ListItemIcon>
        <LegendToggleIcon />
      </ListItemIcon>
      <ListItemText primary="Total Flow" />
    </ListItem> */}
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Other Menu</ListSubheader>
    {/*< ListItem button component={Link} to="/billview">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Bill View" />
    </ListItem>
    <ListItem button component={Link} to="/sitedetails">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Site Details" />
    </ListItem> */}
    { localStorage.getItem("isLoggedIn") ? (
    <ListItem button component={Link} to="/changepass">
      <ListItemIcon>
        <PasswordIcon />
      </ListItemIcon>
      <ListItemText primary="Change Password" />
    </ListItem>
    ) : (
      null
    )}
    { localStorage.getItem("isLoggedIn") ? (
      <ListItem button onClick={logout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    ) : (
      null
    )}
  </div>
);
